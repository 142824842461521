import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const HighValueDocumentsAttestationPage = (props) => {
    const components = props.data.cms.highValueDocumentsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components}></DynamicZone>
        </Layout>
    );
};
export default HighValueDocumentsAttestationPage;

export const query = graphql`
query ($locale: String!){
  cms {
    highValueDocumentsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          primaryImage {
            url
          }
          mobileContentOffset
          contentOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          topPusherHeightMobile
          topPusherHeight
          imagesTailwindClasses
          mobileItemsToLeft
          benefitsScrollColor
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          Benefit {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabHeight
          tabs {
            type
            subtitle
            title
            scrollColor
            label
            imagesMetadata
            images {
              url
            }
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            style
            title
            description
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          topPusherHeight
          topPusherHeightMobile
          image {
            url
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
